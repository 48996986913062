// background-color
%bg-midnight-blue { background-color: var(--blue-70); }
%bg-clear-blue { background-color: var(--blue-40); }
%bg-plum { background-color: var(--purple-70); }
%bg-alabaster { background-color: var(--neutral-10); }
%bg-violet { background-color: var(--purple-50); }
%bg-teal { background-color: var(--teal-30); }
%bg-burst { background-color: var(--red-40); }
%bg-beige { background-color: var(--neutral-10); }
%bg-tangerine { background-color: var(--orange-30); }
%bg-scarlet { background-color: var(--red-40); }

// font-color
%midnight-blue { color: var(--blue-70); }
%clear-blue { color: var(--blue-40); }
%plum { color: var(--purple-70); }
%vermillion { color: var(--orange-30); }
%alabaster { color: var(--neutral-10); }
%violet { color: var(--purple-50); }
%teal { color: var(--teal-30); }
%burst { color: var(--red-40); }
%beige { color: var(--neutral-10); }
%tangerine { color: var(--orange-30); }
%mint { color: var(--teal-40); }
%scarlet { color: var(--red-40); }

.better .color-primary { @extend %teal; }
.better .color-secondary { @extend %teal; }
.better .bg-secondary { @extend %bg-teal; }
.better .hover\:bg-secondary:hover { @extend %bg-teal; }
.better .color-knockout-primary { @extend .white; }
.better .hover\:color-knockout-primary:hover { @extend .white; }
.better .bg-knockout-primary { @extend .bg-white; }

.globalcitizen .color-primary { @extend %mint; }
.globalcitizen .color-secondary { @extend %scarlet; }
.globalcitizen .bg-secondary { @extend %bg-scarlet; }
.globalcitizen .hover\:bg-secondary:hover { @extend %bg-scarlet; }
.globalcitizen .color-knockout-primary { @extend .white; }
.globalcitizen .hover\:color-knockout-primary:hover { @extend .white; }
.globalcitizen .bg-knockout-primary { @extend .bg-white; }

.mach .color-primary { @extend %violet; }
.mach .color-secondary { @extend %violet; }
.mach .bg-secondary { @extend %bg-violet; }
.mach .hover\:bg-secondary:hover { @extend %bg-violet; }
.mach .color-knockout-primary { @extend .white; }
.mach .hover\:color-knockout-primary:hover { @extend .white; }
.mach .bg-knockout-primary { @extend .bg-white; }

.msnbc .color-primary { @extend %clear-blue; }
.msnbc .color-secondary { @extend %midnight-blue; }
.msnbc .bg-secondary { @extend %bg-midnight-blue; }
.msnbc .hover\:bg-secondary:hover { @extend %bg-midnight-blue; }
.msnbc .color-knockout-primary { @extend .white; }
.msnbc .hover\:color-knockout-primary:hover { @extend .white; }
.msnbc .bg-knockout-primary { @extend .bg-white; }

.news .color-primary { @extend %midnight-blue; }
.news .color-secondary { @extend %clear-blue; }
.news .bg-secondary { @extend %bg-clear-blue; }
.news .hover\:bg-secondary:hover { @extend %bg-clear-blue; }
.news .color-knockout-primary { @extend .white; }
.news .hover\:color-knockout-primary:hover { @extend .white; }
.news .bg-knockout-primary { @extend .bg-white; }

.sponsoredcontent .color-primary { @extend .gray-100; }
.sponsoredcontent .color-secondary { @extend .gray-100; }
.sponsoredcontent .bg-secondary { @extend .bg-gray-100; }
.sponsoredcontent .hover\:bg-secondary:hover { @extend .bg-gray-100; }
.sponsoredcontent .color-knockout-primary { @extend .gray-30; }
.sponsoredcontent .hover\:color-knockout-primary:hover { @extend .gray-30; }
.sponsoredcontent .bg-knockout-primary { @extend .bg-gray-30; }

.think .color-primary { @extend %burst; }
.think .color-secondary { @extend %burst; }
.think .bg-secondary { @extend %bg-burst; }
.think .hover\:bg-secondary:hover { @extend %bg-burst; }
.think .color-knockout-primary { @extend .white; }
.think .hover\:color-knockout-primary:hover { @extend .white; }
.think .bg-knockout-primary { @extend .bg-white; }

.today .color-primary { @extend %vermillion; }
.today .color-secondary { @extend %plum; }
.today .bg-secondary { @extend %bg-plum; }
.today .hover\:bg-secondary:hover { @extend %bg-plum; }
.today .color-knockout-primary { @extend %alabaster; }
.today .hover\:color-knockout-primary:hover { @extend %alabaster; }
.today .bg-knockout-primary { @extend %bg-alabaster; }
.today.videoPage .bg-knockout-primary { @extend .bg-black; }

.today.articlePage .bg-knockout-primary,
.today.liveBlogPage .bg-knockout-primary,
.today.recipePage .bg-knockout-primary {
  @extend .bg-white;
}
