:root {
  /* Spacing Values */
  --spacing-0: 0;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 2rem;
  --spacing-8: 3rem;
  --spacing-9: 4rem;
  --spacing-10: 6rem;
  --spacing-11: 8rem;
  --spacing-12: 16rem;
}

/* 758px, 1000px, 1240px */
@custom-media --breakpoint-medium , only screen and (min-width: 47.375em);
@custom-media --breakpoint-large , only screen and (min-width: 62.5em);
@custom-media --breakpoint-xlarge , only screen and (min-width: 77.5em);
