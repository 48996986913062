@use 'assets/styles/utils/helper' as h;
@use 'sass:math';

/*
  This flexbox grid implementation should be more backwards-compatible than a native
  CSS Grid implementation, and can be expanded to use CSS Grid on browsers that support
  it.
  The main way to use this grid is to add the `.layout-grid-container` class to a container
  element, then add `.layout-grid-item` to any "columns" on the page, as well as using
  the functional-css-style `.grid-col-#` classes to specify how many columns the item
  should take up. E.g.:
  ```
    <div className="layout-grid-container">
      <div className="layout-grid-item grid-col-3-m grid-col-4-xl">
        left sidebar column
      </div>
      <div className="layout-grid-item grid-col-9-m grid-col-8-xl">
        right main column
      </div>
    </div>
  ```
  The layout-grid-container is also intended to remove the need for negative margins
  at the mobile breakpoint, by simply making the parent container full width at mobile.
*/

.layout-grid-container {
  display: flex;
  flex-direction: column;
}

.layout-grid-container-s {
  flex-flow: row wrap;
  max-width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
}

// gutter-collapse can be added if you need to have a `layout-grid-container`
// inside of another `layout-grid-container`
.layout-grid-container.gutter-collapse {
  max-width: 100%;
}

.layout-grid-item {
  flex-shrink: 0;
  width: 100%;
  border: 0 solid transparent;
}

// We have limited support for a "subgrid" which can be used to easily break up a
// column container into pieces further.
.layout-subgrid-container {
  display: flex;
  flex-direction: column;
}

.layout-subgrid-item {
  border: 0 solid transparent;
  flex-shrink: 0;
}

// there probably isn't anything that needs to be smaller than 12 cols at mobile breakpoint
.grid-col-12,
.grid-fr-whole {
  width: 100%;
}

.grid-col-6 {
  width: h.$twelve-col-percentage * 6;
}

// The `fr` classes are intended for use on subgrid items
.grid-fr-half {
  width: 50%;
}

.grid-fr-third {
  width: math.div(100%, 3);
}

.grid-fr-quarter {
  width: 25%;
}

.grid-col-push-0 {
  margin-left: 0;
}

.grid-col-push-1 {
  margin-left: h.$twelve-col-percentage;
}

.grid-col-push-2 {
  margin-left: h.$twelve-col-percentage * 2;
}

@media (max-width: h.$grid-m-width - 1) {
  .layout-grid-item--with-gutter-s-only {
    border-left-width: h.$outer-gutter;
    border-right-width: h.$outer-gutter;
  }
}

@media (min-width: h.$grid-m-width) {
  // At M breakpoint and above, `layout-grid-container` is responsible for adding
  // the outer gutter.
  .layout-grid-container {
    flex-flow: row wrap;
    max-width: calc(100% - #{2 * h.$outer-gutter});
    margin-left: auto;
    margin-right: auto;
  }

  .layout-grid-container.gutter-collapse {
    margin-left: h.$neg-outer-gutter;
    margin-right: h.$neg-outer-gutter;
    max-width: calc(100% + #{2 * h.$outer-gutter});
    width: calc(100% + #{2 * h.$outer-gutter});
  }

  .gutter-collapse .layout-grid-item {
    border-left-width: h.$outer-gutter;
    border-right-width: h.$outer-gutter;
  }

  .gutter-collapse-m {
    margin-left: h.$neg-outer-gutter;
    margin-right: h.$neg-outer-gutter;
    width: calc(100% + #{2 * h.$outer-gutter});
  }

  .layout-subgrid-container {
    flex-flow: row wrap;
    margin-left: -2vw;
    margin-right: -2vw;
  }

  .layout-subgrid-item {
    border-left-width: 2vw;
    border-right-width: 2vw;
  }

  // functional-css-style classes work similar to the existing `@include grid-media`
  // mixin, where the column sizing is applied at "X breakpoint and larger".
  .grid-col-1-m {
    width: h.$twelve-col-percentage;
  }

  .grid-col-2-m {
    width: h.$twelve-col-percentage * 2;
  }

  .grid-col-3-m {
    width: h.$twelve-col-percentage * 3;
  }

  .grid-col-4-m {
    width: h.$twelve-col-percentage * 4;
  }

  .grid-col-5-m {
    width: h.$twelve-col-percentage * 5;
  }

  .grid-col-6-m {
    width: h.$twelve-col-percentage * 6;
  }

  .grid-col-7-m {
    width: h.$twelve-col-percentage * 7;
  }

  .grid-col-8-m {
    width: h.$twelve-col-percentage * 8;
  }

  .grid-col-9-m {
    width: h.$twelve-col-percentage * 9;
  }

  .grid-col-10-m {
    width: h.$twelve-col-percentage * 10;
  }

  .grid-col-11-m {
    width: h.$twelve-col-percentage * 11;
  }

  .grid-col-12-m,
  .grid-fr-whole {
    width: 100%;
  }

  .grid-fr-half-m {
    width: 50%;
  }

  .grid-fr-third-m {
    width: math.div(100%, 3);
  }

  .grid-fr-quarter-m {
    width: 25%;
  }

  .grid-col-push-0-m {
    margin-left: 0;
  }

  .grid-col-push-1-m {
    margin-left: h.$twelve-col-percentage;
  }

  .grid-col-push-2-m {
    margin-left: h.$twelve-col-percentage * 2;
  }

  .grid-col-push-3-m {
    margin-left: h.$twelve-col-percentage * 3;
  }

  .grid-col-push-4-m {
    margin-left: h.$twelve-col-percentage * 4;
  }

  .grid-col-push-5-m {
    margin-left: h.$twelve-col-percentage * 5;
  }

  .grid-col-push-6-m {
    margin-left: h.$twelve-col-percentage * 6;
  }

  .grid-col-push-7-m {
    margin-left: h.$twelve-col-percentage * 7;
  }

  .grid-col-push-8-m {
    margin-left: h.$twelve-col-percentage * 8;
  }

  .grid-col-push-9-m {
    margin-left: h.$twelve-col-percentage * 9;
  }

  .grid-col-push-10-m {
    margin-left: h.$twelve-col-percentage * 10;
  }

  .grid-col-push-11-m {
    margin-left: h.$twelve-col-percentage * 11;
  }
}

@media (min-width: h.$grid-l-width) {
  .layout-grid-container {
    flex-flow: row wrap;
    max-width: h.$grid-l-width - 2 * h.$outer-gutter;
  }

  .layout-grid-container.gutter-collapse {
    margin-left: h.$neg-outer-gutter;
    margin-right: h.$neg-outer-gutter;
    max-width: calc(100% + #{2 * h.$outer-gutter});
    width: calc(100% + #{2 * h.$outer-gutter});
  }

  .gutter-collapse-m {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .layout-grid-item {
    border-left-width: h.$outer-gutter;
    border-right-width: h.$outer-gutter;

    &.is-gapless {
      border-left-width: 0;
      border-right-width: 0;
    }
  }

  .layout-subgrid-container {
    margin-left: h.$neg-outer-gutter;
    margin-right: h.$neg-outer-gutter;
  }

  .layout-subgrid-item {
    border-left-width: h.$outer-gutter;
    border-right-width: h.$outer-gutter;
  }

  .grid-col-1-l {
    width: h.$twelve-col-percentage;
  }

  .grid-col-2-l {
    width: h.$twelve-col-percentage * 2;
  }

  .grid-col-3-l {
    width: h.$twelve-col-percentage * 3;
  }

  .grid-col-3_5-l {
    width: h.$twelve-col-percentage * 3.5;
  }

  .grid-col-4-l {
    width: h.$twelve-col-percentage * 4;
  }

  .grid-col-4_5-l {
    width: h.$twelve-col-percentage * 4.5;
  }

  .grid-col-5-l {
    width: h.$twelve-col-percentage * 5;
  }

  .grid-col-6-l {
    width: h.$twelve-col-percentage * 6;
  }

  .grid-col-7-l {
    width: h.$twelve-col-percentage * 7;
  }

  .grid-col-7_5-l {
    width: h.$twelve-col-percentage * 7.5;
  }

  .grid-col-8-l {
    width: h.$twelve-col-percentage * 8;
  }

  .grid-col-9-l {
    width: h.$twelve-col-percentage * 9;
  }

  .grid-col-10-l {
    width: h.$twelve-col-percentage * 10;
  }

  .grid-col-11-l {
    width: h.$twelve-col-percentage * 11;
  }

  .grid-col-12-l,
  .grid-fr-whole {
    width: 100%;
  }

  .grid-fr-half-l {
    width: 50%;
  }

  .grid-fr-third-l {
    width: math.div(100%, 3);
  }

  .grid-fr-quarter-l {
    width: 25%;
  }

  .grid-col-push-0-l {
    margin-left: 0;
  }

  .grid-col-push-1-l {
    margin-left: h.$twelve-col-percentage;
  }

  .grid-col-push-2-l {
    margin-left: h.$twelve-col-percentage * 2;
  }

  .grid-col-push-3-1 {
    margin-left: h.$twelve-col-percentage * 3;
  }

  .grid-col-push-4-1 {
    margin-left: h.$twelve-col-percentage * 4;
  }

  .grid-col-push-5-1 {
    margin-left: h.$twelve-col-percentage * 5;
  }

  .grid-col-push-6-1 {
    margin-left: h.$twelve-col-percentage * 6;
  }

  .grid-col-push-7-1 {
    margin-left: h.$twelve-col-percentage * 7;
  }

  .grid-col-push-8-1 {
    margin-left: h.$twelve-col-percentage * 8;
  }

  .grid-col-push-9-1 {
    margin-left: h.$twelve-col-percentage * 9;
  }

  .grid-col-push-10-1 {
    margin-left: h.$twelve-col-percentage * 10;
  }

  .grid-col-push-11-1 {
    margin-left: h.$twelve-col-percentage * 11;
  }
}

@media (min-width: h.$grid-x-width) {
  .layout-grid-container {
    max-width: h.$grid-x-width - 2 * h.$outer-gutter;
  }

  .layout-grid-container.gutter-collapse {
    margin-left: h.$neg-outer-gutter;
    margin-right: h.$neg-outer-gutter;
    max-width: calc(100% + #{2 * h.$outer-gutter});
    width: calc(100% + #{2 * h.$outer-gutter});
  }

  .grid-col-1-xl {
    width: h.$twelve-col-percentage;
  }

  .grid-col-2-xl {
    width: h.$twelve-col-percentage * 2;
  }

  .grid-col-3-xl {
    width: h.$twelve-col-percentage * 3;
  }

  .grid-col-3_5-xl {
    width: h.$twelve-col-percentage * 3.5;
  }

  .grid-col-4-xl {
    width: h.$twelve-col-percentage * 4;
  }

  .grid-col-5-xl {
    width: h.$twelve-col-percentage * 5;
  }

  .grid-col-6-xl {
    width: h.$twelve-col-percentage * 6;
  }

  .grid-col-7-xl {
    width: h.$twelve-col-percentage * 7;
  }

  .grid-col-8-xl {
    width: h.$twelve-col-percentage * 8;
  }

  .grid-col-9-xl {
    width: h.$twelve-col-percentage * 9;
  }

  .grid-col-10-xl {
    width: h.$twelve-col-percentage * 10;
  }

  .grid-col-11-xl {
    width: h.$twelve-col-percentage * 11;
  }

  .grid-col-12-xl,
  .grid-fr-whole {
    width: 100%;
  }

  .grid-fr-half-xl {
    width: 50%;
  }

  .grid-fr-third-xl {
    width: math.div(100%, 3);
  }

  .grid-fr-quarter-xl {
    width: 25%;
  }

  .grid-col-push-0-xl {
    margin-left: 0;
  }

  .grid-col-push-half-xl {
    margin-left: h.$twelve-col-percentage * .5;
  }

  .grid-col-push-1-xl {
    margin-left: h.$twelve-col-percentage;
  }

  .grid-col-push-2-xl {
    margin-left: h.$twelve-col-percentage * 2;
  }

  .grid-col-push-3-xl {
    margin-left: h.$twelve-col-percentage * 3;
  }

  .grid-col-push-4-xl {
    margin-left: h.$twelve-col-percentage * 4;
  }

  .grid-col-push-5-xl {
    margin-left: h.$twelve-col-percentage * 5;
  }

  .grid-col-push-6-xl {
    margin-left: h.$twelve-col-percentage * 6;
  }

  .grid-col-push-7-xl {
    margin-left: h.$twelve-col-percentage * 7;
  }

  .grid-col-push-8-xl {
    margin-left: h.$twelve-col-percentage * 8;
  }

  .grid-col-push-9-xl {
    margin-left: h.$twelve-col-percentage * 9;
  }

  .grid-col-push-10-xl {
    margin-left: h.$twelve-col-percentage * 10;
  }

  .grid-col-push-11-xl {
    margin-left: h.$twelve-col-percentage * 11;
  }
}
