@media print {
  $logo-y-axis: 30px;

  /*** Globals ***/
  figure picture img {
    max-width: 100%;
  }

  /*** Functional (Toolkit Copycats) ***/
  .db-print {
    display: block !important;
  }

  .di-print {
    display: inline !important;
  }

  .dn-print,
  header::before {
    display: none !important;
  }

  .dg-print {
    display: grid !important;
  }

  .f4-print {
    font-size: 1rem !important;
  }

  .mh0-print {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ml0-print {
    margin-left: 0 !important;
  }

  .mr0-print {
    margin-right: 0 !important;
  }

  .mb0-print {
    margin-bottom: 0 !important;
  }

  .pl0-print {
    padding-left: 0 !important;
  }

  .pt0-print {
    padding-top: 0 !important;
  }

  .pb0-print {
    padding-bottom: 0 !important;
  }

  .black-print {
    color: #000 !important;
  }

  .no-underline-print {
    text-decoration: none;
  }

  .w3-print {
    width: 4rem;
  }

  .lh-none-print {
    line-height: none !important;
  }

  .b-none-print {
    border: none !important;
  }

  .bg-none-print {
    background-color: transparent !important;
  }

  /*** BEM ***/
  .navbar__logo--print {
    span {
      font-size: $logo-y-axis;
      color: #000;
    }

    svg {
      height: $logo-y-axis;
      margin-right: 10px;
      width: 50px;
    }
  }
}
