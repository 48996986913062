@use 'assets/styles/utils/helper' as h;

/***
  .boxinlineAd
  usage: use for Hiding boxInline ads on Desktop
  when there's ads inside packages
*/

.boxinlineAd {
  display: inherit;

  @include h.breakpoint-m {
    display: none;
  }
}

.boxflexAd:first-of-type {
  display: none;

  @include h.breakpoint-l {
    display: inline;
    border-left-width: 0;
    border-right-width: h.rem(20px);
  }

  @include h.breakpoint-x {
    border-left-width: h.rem(20px);
  }
}

.boxflexAd:last-of-type {
  display: none;

  @include h.breakpoint-l {
    display: inline;
    border-left-width: h.rem(20px);
    border-right-width: 0;
  }

  @include h.breakpoint-x {
    border-right-width: h.rem(20px);
  }
}
