@use 'assets/styles/utils/helper' as h;

/***
 cross-layout global styles
**/

// BENTO-6448 ... ad container shift for the extra large viewport to accomodate scrollbar
// when screen is close to 1240
.news .layout-rightRailTabbed .rail .teaseList .ad-container,
.msnbc .layout-rightRailTabbed .rail .teaseList .ad-container,
.news .layout-rightRail .rail .teaseList .ad-container,
.msnbc .layout-rightRail .rail .teaseList .ad-container {
  @include h.breakpoint-x {
    margin-left: -20px;
  }
}

// ad packages need special treatment
// not sure why sometimes ads are hidden by display: none and sometimes by using class
// if a grid container applied directly to a package, it would override
.pkg:not(.lead),
.pkg.layout-grid-container,
.pkg-ad .mps-slot:not([style*='display: none']):not(.mps-gpt-hidden) {
  @include h.package-spacing;
}

.pkg-bottom-border {
  margin: 24px 0 0;
  border: none;
  border-top: 1px solid var(--grey-50);
  height: 1px;
}

body.news .pkg-bottom-border {
  margin: var(--package-spacing) var(--package-spacing) 0;
  border: none;
  border-top: 2px solid var(--grey-50);
  height: 0;

  @include h.breakpoint-m {
    margin: var(--package-spacing) 0 0;
    border-top: 1px solid var(--grey-50);
  }
}

// if an embed has styles specified in the url, we don't want to add additional bottom margin
.pkg.no-margin {
  margin-bottom: 0;
}

.pkg .pkg:last-child {
  margin-bottom: 0;
}

body.today .rail__container {
  @include h.breakpoint-m {
    max-width: 1260px;
    margin: 0 auto;
  }
}
