/*
-----------------------
Customize Toolkit Build
-----------------------
*/

@import './toolkit/_toolkit-config';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_coordinates';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_display';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_flexbox';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_float';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_font-family';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_font-size';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_font-weight';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_height';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_letter-spacing';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_line-height';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_margin';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_overflow';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_padding';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_position';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_text-align';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_text-transform';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_width';
@import '~@nbcnews/toolkit/src/assets/toolkit/css/modules/_z-index';

/* GLOBAL CLASSES */

///! NOTE - the hex colors below belong to the old approach to styling
// colors do not necessarily match to design-token equivalents

// font-colors
.black { color: #000; }
.gray-30 { color: #ddd; }
.gray-40 { color: #ccc; }
.gray-60 { color: #999; }
.gray-80 { color: #555; }
.gray-100 { color: #2a2a2a; }
.white { color: #fff; }
.red { color: #fa3a3a; }
.plum { color: #380538; }
.vermillion { color: #ff503c; }

// background-color
.bg-black { background-color: #000; }
.bg-gray-20 { background-color: #ebebeb; }
.bg-gray-30 { background-color: #ddd; }
.bg-gray-40 { background-color: #ccc; }
.bg-gray-100 { background-color: #2a2a2a; }
.bg-white { background-color: #fff; }
.bg-transparent { background-color: transparent; }

// border-color
.b-gray-40 { border-color: #ccc; }
.b-clear-blue { border-color: #3061ff; }

// border-width
.bw-1px { border-width: 1px; }
.bw0 { border-width: 0; }
.bt-0 { border-top-width: 0; }
.br-0 { border-right-width: 0; }
.bb-0 { border-bottom-width: 0; }
.bl-0 { border-left-width: 0; }

// border-style
.b-solid { border-style: solid; }
.b-none { border-style: none; }

// border-radius
.br2 { border-radius: 5px; }

// font-style
.fs-italic { font-style: italic; }

// opacity
.o-100 { opacity: 1; }
.o-60 { opacity: 0.6; }
.o-30 { opacity: 0.3; }
.o-0 { opacity: 0; }

// text-decoration
.strike { text-decoration: line-through; }

// visibility
.v-hidden { visibility: hidden; }

// whitespace
.ws-nowrap { white-space: nowrap; }

// wordspace
.ws-tight { word-spacing: -0.1em; }

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import './toolkit/_themed-toolkit-colors';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import './toolkit/_themed-toolkit-fonts';
