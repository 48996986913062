@use 'assets/styles/utils/helper' as h;

// Global classes for reuse application-wide

.clearfix {
  @include h.clearfix;
}

/***
  .v-center, .h-center, .vh-center
  usage: apply to absolutely positioned elements with non-static parent container
*/
.v-center {
  top: 50%;
  transform: translateY(-50%);
}

.h-center {
  left: 50%;
  transform: translateX(-50%);
}

.vh-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/***
  .css-reset-btn
  usage: remove default browser button styles
*/
.css-reset-btn {
  border: none;
  background: transparent;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  color: inherit;
}

/***
  .fade-in .fade-out
  usage: simple visibility animation with opacity toggle fallback
*/

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.fade-in {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

/***
  .color-inherit
  usage: handy for inner elements of buttons and links
*/

.color-inherit {
  color: inherit;
}

.verticalScrollDisabled {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
}

/**
BENTO-9321: Fixes the pointer event on up packages that includes a video player
*/

.pointer-events-initial {
  pointer-events: initial;
}
